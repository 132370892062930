import Footer from "components/footer";
import { Icon } from "components/icons/styles/icons";

export function FooterContainer() {
  return (
    <Footer>
      <Footer.Wrapper>
        <Footer.Row>
          <Footer.Column>
            <Footer.Title>Nosotros</Footer.Title>
            <Footer.Link href="#">Historia</Footer.Link>
            <Footer.Link href="#">Junta Directiva</Footer.Link>
            <Footer.Link href="#">Organizacion Administrativa</Footer.Link>
            <Footer.Link href="#">Estructura Organizativa</Footer.Link>
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Servicios</Footer.Title>
            <Footer.Link href="#">Retiros de Haberes</Footer.Link>
            <Footer.Link href="#">Préstamos Personales</Footer.Link>
            <Footer.Link href="#">Operaciones</Footer.Link>
            <Footer.Link href="#">Planes y Beneficios</Footer.Link>
            <Footer.Link href="#">Marco Legal</Footer.Link>
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Contacto</Footer.Title>
            <Footer.Link href="#">Operaciones</Footer.Link>
            <Footer.Link href="#">Planes y Beneficios</Footer.Link>
            <Footer.Link href="#">Operaciones</Footer.Link>
            <Footer.Link href="#">Secretaría</Footer.Link>
          </Footer.Column>
          <Footer.Column>
            <Footer.Title>Redes Sociales</Footer.Title>
            <Footer.Link href="#">
              <Icon className="fab fa-twitter" />
              Twitter
            </Footer.Link>
            <Footer.Link href="#">
              <Icon className="fab fa-instagram" />
              Instagram
            </Footer.Link>
            <Footer.Link href="#">
              <Icon className="fab fa-facebook-f" />
              Facebook
            </Footer.Link>
            <Footer.Link href="#">
              <Icon className="fab fa-youtube" />
              Youtube
            </Footer.Link>
          </Footer.Column>
        </Footer.Row>
      </Footer.Wrapper>
    </Footer>
  );
}
