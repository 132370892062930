import InfoSection from "components/Sections/InfoSection/InfoSection";

import {
  homeHero,
  homeObjOne,
  homeObjTwo,
  homeObjThree,
} from "components/Data/Data";
import "../../App/App.css";
import HeroSection from "components/Sections/HeroSection/HeroSection";

/* import Slider from "../Slider/Slider"; */

const Principal = () => {
  return (
    <>
      {/* <Slider /> */}
      <HeroSection {...homeHero} />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />

      {/* <h1 className="principal">Principal</h1> */}
    </>
  );
};

export default Principal;
