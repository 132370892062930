import styled from "styled-components";

import img from "./img66.jpg";
import LoginForm from "./LoginForm";
import { heroTitle } from "components/Data/Data";

function HeroSection({ heroTitle, heroDesc }) {
  return (
    <>
      <SectionWrapper>
        <LeftSide>
          <HeroTitle>{heroTitle}</HeroTitle>
          <HeroDescription>{heroDesc}</HeroDescription>
        </LeftSide>
        <RightSide>
          <LoginForm />
        </RightSide>
      </SectionWrapper>
    </>
  );
}

export default HeroSection;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  /* background-color: aquamarine; */
  background-image: url(${img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-height: 80vh;
  }
`;

const LeftSide = styled.div`
  font-family: "Roboto Condensed", sans-serif;

  box-sizing: border-box;
  justify-self: start;
  align-self: center;
  width: 100%;
  height: 40vh;
  margin: 1rem;
  padding: 5px;
  margin: 10px;
  background: rgba(0, 10, 0, 0.25);
  z-index: 52;
`;
const HeroTitle = styled.h1`
  /* position: absolute; */
  color: #eeebf8;
  /*  top: auto; */
  padding-left: 2rem;
  padding-top: 2rem;
  font-size: 64px;
  font-weight: 400;
  letter-spacing: 0.25rem;
`;
const HeroDescription = styled.p`
  padding-left: 2rem;
  padding-right: 2rem;
  color: #eeebf8;
  text-align: justify;
  font-weight: 300;
  letter-spacing: 0.1rem;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const RightSide = styled.div`
  justify-self: end;
  box-sizing: border-box;
  /*  justify-self: start; */
  width: 100%;
  height: 40vh;
  margin: 1rem;
  padding: 20px;
  margin: 10px;
  background: rgba(0, 10, 0, 0.25);
  z-index: 52;
  h3 {
    color: eeebf8;
  }

  @media screen and (max-width: 768px) {
    max-height: auto;
    justify-content: center;
  }
`;
