import img7 from "images/img7.jpg";
export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Historia",
  headLine: "Semblanza de nuestros inicios",
  description:
    "El día 22 de Junio de 1995, 53 funcionarios adscritos al Servicio Nacional Integrado de Administración Tributaria (SENIAT) se reunieron a objeto de constituir lo que es hoy  “La Caja de Ahorro y Previsión de los Empleados del Servicio Nacional Integrado de Administración Aduanera y Tributaria SENIAT – CAPRES”; teniendo por objeto principal establecer y fomentar el ahorro sistemático y en general procurar para sus asociados toda clase de beneficios socioeconómicos.",
  buttonLabel: "Get Started",
  imgStart: "",
  alt: "Credit Card",
  start: "",
};

export const homeObjTwo = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Servicios",
  headLine: "Préstamos Personales",
  description:
    "Constituyen una alternativa para nuestros afiliados, por cuanto les proporciona una gama de beneficios, entre los cuales se destacan: prestamos monetarios, créditos personales, entre otros, los cuales son cancelados en cómodas cuotas a corto, mediano y largo plazo, según sus ingresos.",
  buttonLabel: "Inicio Libre",
  imgStart: true,

  alt: "Credit Card",
  start: true,
};
export const homeObjThree = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Servicios",
  headLine: "Préstamos Personales",
  description:
    "El trabajo que se realiza a diario busca resultados concretos para otorgar a los Asociados una mejor calidad de vida, al entender que así, y solo por esta vía, hemos de contribuir al logro de los altos fines propuestos, como es incentivar al Ahorro para obtener beneficios a futuro, teniendo como visión ser reconocida por sus Asociados por la excelencia en servicios consolidados.",
  buttonLabel: "Inicio Libre",
  imgStart: false,

  alt: "Credit Card",
  start: true,
};

export const homeHero = {
  heroTitle: "Capres",
  heroDesc:
    "La Caja de Ahorros de los Empleados del SENIAT les da las más cordiales palabras de BIENVENIDA a nuestro nuevo portal, el cual tiene como misión fundamental mantener informados a nuestros Asociados en sus líneas maestras que son su razón de ser.",
  loginForm: true,
};
