import "../../App/App.css";
const Servicios = () => {
  return (
    <div>
      <h1 className="servicios">Servicios</h1>
    </div>
  );
};

export default Servicios;
