import "../../App/App.css";
const Socios = () => {
  return (
    <div>
      <h1 className="socios">Socios</h1>
    </div>
  );
};

export default Socios;
