import { useState } from "react";
import { Button } from "components/Button/Button";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Principal from "components/pages/Principal";
import Servicios from "components/pages/Servicios";
import Socios from "components/pages/Socios";
import Contacto from "components/pages/Contacto";
import Registro from "components/pages/Registro";
import LogIn from "components/pages/LogIn";
import "./Navbar.css";
import logo from "images/capres.jpg";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <Router>
        <nav className={navbar ? "navbar active" : "navbar"}>
          <Link to="/" exact="true" className="navbar-logo">
            <img src={logo} alt="logo-capres" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Principal
              </Link>
            </li>
            <li
              className="nav-item"
              /* onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave} */
            >
              <Link
                to="/servicios"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Servicios {/* <i className="fas fa-caret-down" /> */}
                {/* {dropdown && <Dropdown />} */}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/socios"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Socios
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contacto"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Contacto
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/registro"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                Registro
              </Link>
            </li>
          </ul>
          {button && <Button buttonStyle="btn--outline">REGISTRO</Button>}
        </nav>
        <Switch>
          <Route path="/" exact={true} component={Principal}></Route>
          <Route path="/servicios" component={Servicios} />
          <Route path="/socios" component={Socios} />
          <Route path="/contacto" component={Contacto} />
          <Route path="/registro" component={Registro} />
          <Route path="/login" component={LogIn} />
        </Switch>
      </Router>
    </>
  );
}

export default Navbar;
