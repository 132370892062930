import { FooterContainer } from "containers/footer";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import React from "react";
import Navbar from "components/Navbar/Navbar";

/* const App = () => {
  return (
    <>
      <Navbar />

      <FooterContainer />
    </>
  );
}; */
const App = withRouter(({ location }) => {
  return (
    <>
      <Router>
        {location.pathname !== "/servicios" && <Navbar />}
        <FooterContainer />
      </Router>
    </>
  );
});

export default App;
